// variable
@use 'variables' as var;

@font-face {
    font-family: mont;
    src: url('../assets/fonts/mont/Mont-Thin.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: mont;
    src: url('../assets/fonts/mont/Mont-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: mont;
    src: url('../assets/fonts/mont/Mont-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: mont;
    src: url('../assets/fonts/mont/Mont-Regular.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: mont;
    src: url('../assets/fonts/mont/Mont-Bold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: mont;
    src: url('../assets/fonts/mont/Mont-Heavy.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: mont;
    src: url('../assets/fonts/mont/Mont-Black.ttf') format('truetype');
    font-weight: 800;
}

* {
    font-family: mont;
}

.text-orange {
    color: var(--primary-color);
}

.text-dark {
    color: var(--secondary-color);
}

.btn-orange {
    background-color: var(--primary-color);
    color: #fff;
}

.btn-orange:hover {
    background-color: #ff7c10;
    color: #ddd;
}

.text-underline {
    text-decoration: underline;
}

.select-wrapper {
    border: 1px solid #c6c6c6;
    border-radius: 50px;
    padding: 0 10px;
    margin-right: 10px;
    height: 30px;
    font-size: 12px;
    font-weight: 500;

    .rounded-select {
        border: none;
        background: transparent;
        padding: 0px;
        height: inherit;
        font-size: inherit;
        color: #000;
        padding-right: 10px;
        font-weight: inherit;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.label-text {
    margin: 10px;
    font-weight: 600;
    font-size: 12px;
    margin-right: 15px;
    margin-top: 6px;
    white-space: nowrap;
}

.custom-link {
    cursor: pointer;
}

.c-inline {
    float: left !important;
}

.c-main {
    padding-top: 25px;
    background: #f5f5f5;
    position: relative;
}

/* 
    Sub Components 
*/
// search component
.search-form {
    display: flex;
    border: 1px solid #c6c6c6;
    border-radius: 50px;
    // margin: 0 0 30px;

    input[type='search'] {
        border: none;
        background: transparent;
        margin: 0;
        padding: 0px 10px;
        font-size: 12px;
        color: inherit;
        border-radius: inherit;
        height: 30px;
    }

    input[type='search']::placeholder {
        color: #c6c6c6;
    }

    button[type='submit'] {
        text-indent: -999px;
        overflow: hidden;
        width: 40px;
        padding: 0;
        margin: 0;
        border: 1px solid transparent;
        border-radius: inherit;
        background: transparent
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
            no-repeat center;
        cursor: pointer;
        opacity: 0.7;
    }

    button[type='submit']:hover {
        opacity: 1;
    }

    button[type='submit']:focus,
    input[type='search']:focus {
        box-shadow: 0 0 3px 0 #1183d6;
        border-color: #1183d6;
        outline: none;
    }
}

.table-profile-image {
    border-radius: 50%;
}

.table-status {
    line-height: 1.4;
    margin: 0;
    list-style: none;
    padding: 0;
    font-size: 10px;
    white-space: nowrap;

    span {
        border-radius: 20px;
        width: 6px;
        height: 6px;
        background-color: var(--secondary-color);
        display: inline-block;

        &.active {
            background-color: var(--primary-color);
        }
    }
}

.icon-h-100 {
    height: 100px;
}

.cursor-pointer {
    cursor: pointer;
}

.modal {
    backdrop-filter: blur(3px);
}

.modal-header {
    border-bottom: 0 !important;
}

.modal-content {
    border-radius: 20px !important;
}

.modal-header {
    font-size: 24px !important;
}

.modal-body .container {
    overflow-y: auto !important;
}

.page-link {
    z-index: 0 !important;
}

.pac-container {
    z-index: 9999;
}

.see-more {
    width: 475px;

    h5 {
        font-size: 13px !important;
    }

    button {
        font-size: 10px !important;
        color: #000;
    }
}

// Sidebar
.c-sidebar {
    background: var(--secondary-color);
    box-shadow: 0px 0px 20px 0px #767676;
    z-index: 1 !important;

    .c-sidebar-brand {
        background: none;

        img {
            width: 100px;
            margin: auto;
        }
    }

    .c-sidebar-nav-dropdown.c-show {
        background: #ffffff26;

        .c-sidebar-nav-dropdown-items {
            li {
                a {
                    color: #ffffffa6;
                    font-weight: 500;
                    padding-left: 45px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }
            }
        }

        .c-sidebar-nav-link,
        .c-sidebar-nav-dropdown-toggle {
            &.c-active {
                background: none;
                font-weight: 600;
                color: #ffffff;
            }
        }
    }

    .c-sidebar-nav-link,
    .c-sidebar-nav-dropdown-toggle {
        padding: 5px 1rem;
        color: #ffffffe0;
        font-weight: 600;

        &:hover {
            background: none;
        }

        .c-sidebar-nav-icon {
            color: #ffffffe0;
            width: 30px;
            flex: none;
            padding-left: 15px;
            height: 13px;
        }
    }

    .c-sidebar-nav {
        span {
            font-weight: 600;
            padding: 20px;
            color: #ffffffe0;
        }
    }
}

// Header
.c-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 1px 1px 7px #cbcbcb;
    flex-wrap: nowrap;

    .headerTitle {
        font-size: 16px;
        margin: 0;
        font-weight: 700;
        margin-right: 10px;
        color: var(--secondary-color);
        letter-spacing: 1px;
        line-height: 1;
    }

    .c-header-nav {
        .c-header-nav-item {
            display: flex;
            align-items: center;
        }
    }

    .checkbox-filter {
        white-space: nowrap;
        margin-bottom: 0;
        margin-left: 20px;
        font-size: 12px;
        color: #000;
        line-height: 2;
    }

    .checkbox-filter-label {
        margin-bottom: 0;
        font-weight: 600;
    }
}

.staffyProfile {
    display: flex;
    align-items: center;

    .img-wrapper {
        position: relative;
        margin-right: 10px;

        span.sign {
            position: absolute;
            bottom: -12px;
            color: var(--secondary-color);
            left: 0;
            right: 0;
            font-size: 18px;

            svg {
                background: #fff;
                border-radius: 50%;
            }

            &.active {
                color: var(--primary-color);
            }
        }
    }

    img.profile-img {
        border-radius: 50%;
        min-width: 50px;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    .profileDetails {
        width: 180px;
        text-align: left;

        h1 {
            font-size: 16px;
            margin-bottom: 0;
            text-decoration: underline;
            font-weight: 600;
            display: block;
            line-height: 1;
            margin-bottom: 5px;
            cursor: pointer;
            text-transform: capitalize;
            word-break: break-word;

            span {
                background: var(--secondary-color);
                width: 15px;
                height: 15px;
                float: left;
                border-radius: 25px;
                margin-right: 5px;

                &.active {
                    background: var(--primary-color);
                }
            }
        }

        ul {
            display: flex;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                line-height: 1;
                margin-right: 15px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.react-tiny-popover-container {
    z-index: 999;
}

.iconPopover {
    display: block;
    background: #ffff;
    padding: 10px 20px;
    box-shadow: 1px 1px 16px #bfbfbf;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    border-radius: 3px;
    position: relative;
    z-index: 99999999999;

    &.customArrow::after {
        content: '';
        position: absolute;
        width: 0;
        top: -7px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        border-left: 8px dotted transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
    }
}

.optionsBox {
    background: #fff;
    padding: 40px;
    max-width: 550px;
    min-width: 400px;
    box-shadow: 0px 0px 25px #d9d9d9;
    border-radius: 20px;
    padding-top: 20px;
    margin-top: 10px;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        white-space: nowrap;
        font-weight: 500;
        cursor: pointer;
        font-size: 12px;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: var(--primary-color);
            }
        }
    }

    > ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        li {
            margin-right: 20px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .options {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }

        p {
            font-weight: 600;
            margin-bottom: 0px;
        }

        small {
            font-weight: 600;
        }

        li {
            margin-right: 20px;
            font-size: 10px;
            display: inline-block;
        }
    }
}

.inputFormGroup {
    margin-bottom: 10px;

    input {
        border-radius: 20px;
        height: 30px;
        color: #000;
        font-size: 12px;
        font-weight: 500;
        border-color: #cdcdcd;

        :focus {
            color: #000;
        }

        &::placeholder {
            color: #000;
        }
    }

    label {
        margin-bottom: 5px;
        line-height: 1;
        font-size: 12px;
        margin-left: 3px;
    }

    textarea {
        border-radius: 15px;
        color: #000;
        border-color: #cdcdcd;
    }
}

html:not([dir='rtl']) .c-sidebar-nav-dropdown-toggle::after {
    transform: rotate(180deg) !important;
}

html:not([dir='rtl'])
    .c-sidebar-nav-dropdown.c-show
    > .c-sidebar-nav-dropdown-toggle::after {
    transform: rotate(-90deg) !important;
}

.modal.show {
    -webkit-transform: none;
    overflow-y: scroll !important;
    overscroll-behavior-y: contain;
    transform: none;
    display: flex !important;
    align-items: center !important;
}

.modal.show .modal-dialog {
    width: 70% !important;
    max-height: 70% !important;
    &::after {
        content: ' ';
        display: block;
        height: 100px;
        padding: 50px;
    }
}

.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background-color: white;
    border: 1px solid;
    outline: none;
    position: relative;

    &:checked {
        background-color: red;

        &::before {
            color: white;
        }
    }

    &::before {
        content: '\2713';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 54%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        color: transparent;
    }
}
.rc-slider-with-marks {
    padding-bottom: 20px !important;
    // for slider alignment issues
    margin-left: 8px;
    margin-right: 6px;
    width: calc(100% - 14px) !important;
    .rc-slider-mark {
        left: 15px;
        width: 97%;
        // .rc-slider-mark-text:first-child {
        //     left: 2% !important;
        // }
        // .rc-slider-mark-text:last-child {
        //     left: 99% !important;
        // }
    }
}
