@import "node_modules/@coreui/coreui/scss/functions";
@import "node_modules/@coreui/coreui/scss/variables";

// Variable overrides
$sidebar-width: 200px;

// Custom Variables
:root {
    --primary-color: #FA4D09;
    --secondary-color: #1D2C3A;
}